import { useEffect } from "react"

export function checkMinimumViewportSize() {
  const viewport = document.head.querySelector('meta[name="viewport"]')

  if (window.screen.width < 500) {
    viewport.setAttribute('content', 'width=500')
  } else {
    viewport.setAttribute('content', 'width=device-width, initial-scale=1, shrink-to-fit=no')
  }
}

export function useMinimumViewport() {
  useEffect(() => {
    checkMinimumViewportSize()
    window.addEventListener('resize', checkMinimumViewportSize)
    return () => window.removeEventListener('resize', checkMinimumViewportSize)
  }, [])
}
