import * as ReactDOM from "react-dom/client";
import { checkMinimumViewportSize } from "./src/helpers/viewport";
import "./src/styles/global.css";

export function onClientEntry() {
  checkMinimumViewportSize();
}

// https://beareact.dev/gatsby-browser-console-errors-in-production/
// No idea why the hydration was messed up, but this disables hydration and does full re-render
export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};
